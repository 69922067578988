.wrapper {
  display: flex;
  position: relative;
  width: 100%;
}

input[type=text],
textarea {
  width: 100%;
  padding: 5px;
  border: 15px solid white;
  border-radius: 35px;
  resize: vertical;
  font-size: 25px;
  font-family: HR;
  font-weight: normal;
  outline: none;
}

input[type=text]::placeholder,
textarea::placeholder {
  color: #9e9e9d;
  font-size: 25px;
  font-family: HR;
  font-weight: normal;
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {

    input[type=text],
    textarea,
    input[type=text]::placeholder,
    textarea::placeholder {
      font-family: none;

    }
  }
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type=submit] {
  background-image: url(../../assets/contactusButton.png);
  color: white;
  background-size: auto;
  border: none;
  /* cursor: pointer; */

}

input[type=submit]:hover {
  background-color: #45a049;
}


.container {
  display: flex;
  border-radius: 5px;
  font-family: HR;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 100%;
  font-family: HR;
  border-radius: 30px;
  font-size: 35px;
  margin-top: 6px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* 
@media screen and (max-width: 800px) {
  .col-75,
  input[type=submit] {
    width: 100%;
    margin-top: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
} */

.letter_arrow {
  margin: 0 !important;
  margin-left: 50px !important;
}

.submitBtn {
  background-image: url(../../assets/contactusButton.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% contain;
  cursor: pointer;
}

.submitBtnStyle {
  background-image: url(../../assets/contactusButton.png);
  background-size: cover;
  width: 500px;
  height: 200px;
  font-size: 22rem;
}

.letter_arrow_submit {
  width: 40px;
  height: auto;
  margin-right: 25px;
  justify-content: center;
  align-self: center;
}

.sendBtn {
  font-size: 40px;
  text-align: center;
  padding-top: 12px;
  /* padding-bottom: 16px; */
  color: white;
  font-family: AR;
  width: 100%;
  /* cursor: pointer; */
}

.contentus-section {

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/contactus_main_bg.png');
}

.contactusBg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  padding-top: 120px;
  padding-bottom: 190px;
}

.contactusBg .h2 {
  color: #fff;
  margin-bottom: 35px;
  font-family: AR;
}

.contentus-section .left-item {
  max-width: 775px;
  width: 50%;
}

.contentus-section .right-item {
  width: 50%;
  padding-right: 100px;
}

.contentus-section .right-item form {
  position: relative;
  width: 100%;
}

.contentus-section .right-item form input[type=text] {
  height: 70px;
}

.contentus-section .right-item .arrow-wrapper {
  width: auto;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentus-section .right-item .letter_arrow {
  height: 51px;
  width: 43.3px;
  margin-right: 50px;
}

.contentus-section .left-item img {
  width: 80%;
}

.contentus-section .right-item {
  max-width: 720px;
}

.contentus-section .right-item form .row {
  padding-bottom: 35px;
}

@media screen and (max-width: 1400px) {
  .contentus-section .left-item {
    margin-right: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .contactusBg {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .contactusBg {
    flex-direction: column;
  }

  .contactusBg .h2 {
    margin-bottom: 20px;
  }

  .contentus-section .right-item,
  .contentus-section .left-item {
    max-width: 100%;
    width: 100%;
    padding-right: 0;
  }

  .contentus-section .left-item {
    max-width: 250px;
    text-align: right;
  }

  .contentus-section .right-item .arrow-wrapper {
    display: none;
  }

  .contentus-section .right-item form .row {
    padding-bottom: 15px;
  }

  .contentus-section .right-item form input[type=text] {
    font-size: 16px;
    height: 50px;
  }

  .contentus-section .right-item form textarea {
    font-size: 16px;
  }

  input[type=text]::placeholder,
  select::placeholder,
  textarea::placeholder {
    font-size: 16px;
  }

  .sendBtn {
    font-size: 20px;
  }

  .submitBtn>div {
    justify-content: center !important;
  }

  .submitBtn {
    background-position: center;
    background-size: auto 100%;

  }

  .contentus-section .right-item .letter_arrow {
    height: 25.5px;
    width: 21.65px;
  }
}