.opm_feature_2 {
    display: flex;
    align-items: center;
    background-image: url("../../assets/solution_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.opm_feature_overlay {
    display: flex;
    justify-content: space-between;
    align-items: center;

    opacity: 0.25;
    background-image: url("../../assets/debug.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.descArea {
    padding-top: 80px;
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 100px;
}

.content {
    color: white;
    padding-right: 20px;
    padding-left: 20px;
}

.content {
    color: white;

}

.content h2 {
    font-size: 50px;
    color: white;
}

.content p {
    font-size: 22px;
    color: white;
}