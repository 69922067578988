.wrapper {
  /* important styles */
  display: flex;
  position: relative;
  width: 100%;

  /* extra styles */


  /* margin: 0 auto; */
  /* extra styles */


}

.sidebarTitle {
  opacity: 0.83;
  padding-top: 30px;

  font-family: HelveticaNeue;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-top: 30px;
  letter-spacing: normal;
  text-align: left;
  color: #c2c2c2;
}

.liPadding {
  margin-top: 15px;
}

.sidebarB {
  width: 130px;
  height: 58px;
  font-family: Antonio;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c2c2c2;
}



.sidebar {
  /* important styles */
  flex-shrink: 0;
  width: 200px;
  /* make this whatever you need */
  height: 100vh;
  overflow: hidden;
  position: sticky;
  top: 0;

  /* 
      you could use `position: absolute` above, but using
      `sticky` means we don't need to separately move the
      content section over to prevent the elements from
      overlapping
    */

  /* extra styles */
  /* padding: 30px; */

}

.opm_service_content_main {
  /* important styles */
  flex-grow: 1;
  width: calc(100% - 380px);
  color: black;
  padding-left: 67px;

  /* extra styles */



}

/* .opm_service_content p {
    color: black;
  } */

/* make the nav take up more room :P */
.nav li {
  /* line-height: 1.5; */
}