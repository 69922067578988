.joinUs-filter-item{
    width: 280px;
    margin-left: 20px;
}
.joinUs-filter-item:first-child{
    margin-left: 0;
}
.joinUs-filter-item label{
    color:#e1001e;
    font-family: 'AB';
}
.joinUs-filter-item .k-picker-solid{
    border: 0 ;
    outline: 0;
    background: transparent;
    border-bottom: 2px solid #b7b7b7;
    border-radius: 0;
    appearance: none;
    box-shadow: none !important
}
.joinUs-filter-item .k-button-icon{

}
@media screen and (max-width: 640px) {
    .joinUs-filter-item{
        width: 100%;
        margin-left: 0;
    }

  }