

.opm_application_develop{
    display: flex;
    width: 100%;
    margin-right: 165px;

}

.appDevTitleVertical{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 75px;
    /* padding-left: 80px; */
}

.content_line_height{
  font-size: 22px;
  font-family: HR;
  color: #343434;
  line-height: 1.59;
  
}

.nFontPFContent{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
}

.pfsection5_1{
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  padding: 45px;
  background-size: auto 100%;
  flex-direction: column;
  background-image: url(../../assets/2pf.png);
}

.pfsection5_1_text{
  color: #FFF;
  margin-left: 30px;
  font-size: 22px;
  font-family: HR;
  margin-right: 30px;
}

.pfsection5_1_a{
  color: #00b0bd;
  
  font-size: 22px;
  font-family: HR;
  

}

.alignStart{
  align-self: flex-start;
}

.pfsection5_2{
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  padding: 28px;
  background-size: auto 100%;
  flex-direction: column;
  background-image: url(../../assets/4pf.png);
}


.iBroker_title_style{
  font-size: 50px;
  align-self: center;
  font-stretch: normal;
  font-family: AB;
  font-weight: bold;
}
.iBroker_webview_preview_style{
  font-family: AB;
  font-size: 50px;
 
  align-self: center;
}

.iStorageButton{
  font-family: AB;
  font-size: 50px;
  color: #FFFFFF;
  align-self: center;
  
}

.iBroker_title_style_ui{
  line-height: 1.59;
  margin-left: 20px;
  font-family: HR;
}

.appDevOuter{
     width: 100%;
     padding: 0px;
}

.appDevTitleContetnHon{
    display: flex;
    flex-direction: row;
    font-family: Antonio_B;
    justify-content: center;
    
}

.appDevTitleContetnHon h3{
    
    font-size: 70px;
    
    font-family: AB;
    
    
    font-weight: 300;

    font-stretch: normal;
    font-style: normal;
    line-height: 0.64;
    letter-spacing: normal;
    color: #4f7ac7;
}

.appDevTitleContetnHon img{
    width: 68px;
    display: block;
    margin-left: auto;
    align-self: center;
    right: 0px;
    height: 90px    ;
}

.applicationline{
    display: flex;
    align-items: center;
    padding-top: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.applicationline img{
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.applicationImg{
    display: flex;
    align-items: center;
    padding-top: 20px;
    background-repeat: no-repeat;
    
    background-position: center;
    width: 50%;
}

.alignleft{
  align-self: flex-start;
}

.marginBotton_dig{
  margin-bottom: 20px;
}

.applicationImg img{
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.quote{
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: center;
}

.quote label{
    align-self: center ;
    color: #c2c2c2;
    font-size: 22px;
    font-family: HR;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: center;
    margin-top: 10px;
}

.quote img{
    display: flex;
    align-self: flex-start;
}

.quote{
    display: flex;
}

.vertical{
    display: flex;
    flex-direction: column;
    width: 50%;

    padding-left: 20px;
  }

  .vertical h3{
    font-size: 50px;
  }

  
.vertical_content{
    
    height: 84.2px;
    
    object-fit: contain;
    font-family: Helvetica;
    font-size: 22px;
    font-weight: normal;
    
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
}

  
.vertical_content2{
    
    height: 84.2px;
    margin-bottom: 18px;
    object-fit: contain;
    font-family: Helvetica;
    font-size: 22px;
    font-weight: normal;
    margin-left: 16.3px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
  }

.vertical_content_logo{
    width: 93.1px;
    height: 93.1px;

    object-fit: contain;
}

.vertical_title{
    font-size: 50px;
    
    
    
    font-family: Antonio;
    font-size: 50px;
    font-weight: bold;
    
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    
    color: #343434;
}


  
  
  .horizontal{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 78px;
  }

  .applicationdevImg{
    width: 70%;
  }
  .applicationdevImg img{
    width: 100%;
  }

  .vertical_content2{
    font-size: 17px;
    align-self: center;    
    padding-left: 15px;
  }