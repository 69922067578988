.text-50 {
    font-size: 50px;
    line-height: 1.3;
}

.text-34 {
    font-size: 34px;
    line-height: 1.3;
}

.text-22 {
    font-size: 22px;
    line-height: 1.3;
}

.text-23 {
    font-size: 23px;
    line-height: 1.52;
}

.text-113 {
    font-size: 113px;
    line-height: 1.3;
}

.text-70 {
    font-size: 70px;
    line-height: 1.3;
}

.text-40 {
    font-size: 40px;
    line-height: 1.3;
}

.text-90 {
    font-size: 90px;
}

.h2 {
    font-weight: bold;
    line-height: 1.3;
}

.thumb {
    max-width: 100%;
}



.title-style {
    color: #4f7ac7;
    font-family: AL;
}

.title-logo {
    width: 67px;
    height: 90px;
}

.title-line {
    width: 100%;
}

.title-wapper {
    /* margin-right: 80px;*/
    margin-left: 80px;
}

.abStyle {
    font-family: AB;
}

.arStyle {
    font-family: AR;
}

.hrStyle {
    font-family: HR;
}

.hbStyle {
    font-family: HB;
}

.navBarMargin {
    margin-left: 41px;
    margin-right: 41px;
}

@media screen and (max-width: 1024px) {
    .text-90 {
        font-size: 50px;
    }
    .text-50 {
        font-size: 28px;
    }
    .text-40 {
        font-size: 30px;
        line-height: 1.3;
    }
    .text-34 {
        font-size: 24px;
        line-height: 1.3;
    }

    .text-22 {
        font-size: 18px;
    }
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }