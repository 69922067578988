.seperator {
  width: 100%;
  margin: 34px 0 22px;
}

.controls-wrapper {
  width: 100%;
}

.carousel-wrapper {
  width: 100%;
}

.rec-carousel-item:focus {
  outline: none;
  display: none;
  box-shadow: inset 0 0 0px 0px transparent;
}

.rec-pagination {
  outline: none;
  display: none;
  box-shadow: inset 0 0 0px 0px transparent;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.opm_feature {
  background-image: url("../../assets/home_content_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

.featureOuter {

  position: relative;

  /* background-image: url("../../assets/opm_circle_bg.svg"); */
}


.moreString {

  display: none;
  /* background-image: url("../../assets/opm_circle_bg.svg"); */
}

.moreDetail {
  margin-top: 16px;
  align-self: flex-end;
}

.moreDetail img {
  width: 184px;
  object-fit: contain;
}

.featureOuter img {
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;

}

.opmFeatureCircleBg {
  position: relative;
  background-repeat: no-repeat;
  background-image: url("../../assets/opm_circle_bg.svg");

}

.opmFeatureCircleBgContent {
  position: relative;
  text-align: center;
}

.opm_feature_overlay {
  display: flex;
  justify-content: space-between;
  align-items: center;

  opacity: 0.25;
  background-image: url("../../assets/debug.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

.descAreaHome {
  padding: 80px;
  box-sizing: border-box;
}

.content {
  color: white;
  padding-right: 20px;
  padding-left: 20px;
}

.content {
  color: white;

}

.content h2 {
  font-size: 50px;
  color: white;
}

.content p {
  font-size: 22px;
  color: white;
}

@media only screen and (max-width: 767px) {
  .moreDetail img {
    width: 90px;
  }
}