.opm_application_develop {
    display: flex;
    width: 100%;
    /* padding-right: 100px; */

}

.appDevTitleVertical {
    display: flex;
    flex-direction: column;

}


.consulting-wapper {

    margin-right: 80px;

}

.left,
.right {
    flex: 1;
}



.appDevOuter {
    padding-left: 0px;
}

.featureCenter {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    align-items: center;
    padding-left: 20px;
}

.featureCenter_p1 {
    font-size: 50px;
}

.featureCenter_p2 {
    font-size: 22px;
    color: #343434;
    padding-top: 15px;
    text-align: center;
}

.appDevTitleContetnHon {
    display: flex;
    flex-direction: row;
}

.appDevTitleContetnHon h3 {

    font-size: 70px;

    font-family: Antonio;

    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    align-self: center;
    line-height: 0.64;
    letter-spacing: normal;
    color: #4f7ac7;
}

.appDevTitleContetnHon img {
    display: block;
    margin-left: auto;
    align-self: center;
    right: 0px;
    object-fit: contain;
}

.applicationline {
    display: flex;
    align-items: center;
    padding-top: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.applicationline img {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.applicationImg {
    display: flex;
    align-items: center;
    padding-top: 20px;
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
}

.applicationImg img {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.quote {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
}

.quote label {
    align-self: baseline;
    color: #c2c2c2;
    text-align: center;
}

.quote img {
    display: flex;
    align-self: flex-start;
}

.quote {
    display: flex;
}

.vertical {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
}

.verticallogo {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding-left: 20px;

}


.vertical h3 {
    font-size: 50px;
}


.consult_vertical_content {
    font-size: 22px;
    text-align: center;
    color: #343434;
    padding-bottom: 15px;
}

.consult_vertical_title {
    text-align: center;
    font-size: 50px;
    padding-bottom: 15px;
}



.horizontal {
    display: flex;
    flex-direction: row;
}

.applicationImg {
    justify-self: start;
}

.consult_applicationImg {
    justify-self: center;
}

.consult_vertical_content2 {
    font-size: 17px;
    text-align: center;
    align-items: center;
    align-self: center;
    padding-left: 15px;
}

.consultImg {
    display: flex;
    align-items: center;

    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}

.consultImg img {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
}

.consulting-feature-wrapper {
    display: flex;
    max-width: 1260px;
    margin: auto;
    align-items: flex-start;
    margin-bottom: 80px;
    margin-top: 50px;

}

.consulting-feature-wrapper .consulting-feature-item>div {
    padding: 0 30px;
}

.consulting-feature-wrapper .consulting-feature-item:nth-child(2)>div {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

.consulting-feature-wrapper .consulting-feature-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 33%;
}

.consulting-feature-wrapper .consulting-feature-item img {
    width: 93px;
    height: 93px;
    margin-bottom: 15px;
}

.counsulting-remark {
    margin-bottom: 70px;
}

.counsulting-remark span {
    display: inline-block;
    padding-bottom: 20px;
    padding-top: 10px;
    /* border-bottom: 8px solid #f8ca42; */
    padding-left: 80px;
    background-image: url("../../assets/Consulting\ Services-14.png");
    background-repeat: no-repeat;
    background-size: 42px;
    background-position: left top;
    position: relative;
}

.counsulting-remark span:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: calc(100% - 80px);
    background-color: #f8ca42;
    height: 8.5px;
}

@media screen and (max-width: 1400px) {
    .consulting-feature-wrapper {
        flex-direction: column;
        margin-bottom: 40px;
        margin-top: 25px;
    }

    .consulting-feature-wrapper .consulting-feature-item img {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
    }

    .consulting-feature-wrapper .consulting-feature-item {
        width: 100%;
        max-width: 400px;
        margin: auto;
        margin-bottom: 20px;
    }

    .consulting-feature-wrapper .consulting-feature-item>div {
        border: 0 !important;
        padding: 0;
    }
}

@media screen and (max-width: 1024px) {
    .consulting-feature-wrapper .consulting-feature-item img {
        width: 45px;
        height: 45px;
        margin-bottom: 5px;
    }

    .counsulting-remark {
        margin-bottom: 30px;
    }

    .counsulting-remark span {
        padding-bottom: 15px;
        padding-top: 5px;
        padding-left: 30px;
        padding-right: 30px;
        background-size: 25px;
    }

    .counsulting-remark span:after {
        width: calc(100% - 60px);
        height: 4.5px;
    }
}