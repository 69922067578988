.opm_application_develop{
    display: flex;
    width: 100%;
    padding-right: 100px;

}

.appDevTitleVertical{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.appDevOuter{
     padding-left: 30px; 
}

.appDevTitleContetnHon{
    display: flex;
    flex-direction: row;
}

.appDevTitleContetnHon h3{
    
    font-size: 70px;
    
    font-family: Antonio;
    
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    align-self: center;
    line-height: 0.64;
    letter-spacing: normal;
    color: #4f7ac7;
}

.appDevTitleContetnHon img{
    width: 68px;
    display: block;
    margin-left: auto;
    align-self: center;
    right: 0px;
    height: 90px    ;
}

.applicationline{
    display: flex;
    align-items: center;
    padding-top: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.applicationline img{
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.applicationImg{
    display: flex;
    align-items: center;
    padding-top: 20px;
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
}

.applicationImg img{
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.quote{
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
}

.quote label{
    align-self: baseline;
    color: #c2c2c2;
}

.quote img{
    display: flex;
    align-self: flex-start;
}

.quote{
    display: flex;
}

.vertical{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 20px;
  }

  .vertical h3{
    font-size: 50px;
  }

  
.vertical_content{
    font-size: 22px;
    color: #343434;
}

.vertical_title{
    font-size: 50px;
}


  
  
  .horizontal{
    display: flex;
    flex-direction: row;
  }

  .applicationImg{
    justify-self: start;
  }

  .vertical_content2{
    font-size: 17px;
    align-self: center;    
    padding-left: 15px;
  }