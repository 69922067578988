/* desktop: 1920ox */
/* desktop-1:1400px */
/* tab:1024px */
/* mobile:640px */

.info-section{
    padding: 0 25px;
    padding-top: 220px;
    padding-bottom: 140px;
    max-width: 1480px;
    width: 100%;
    margin: auto;
}
.info-section .item{
    display: flex;
    margin-bottom: 140px;
}

.info-section .item:last-child{
    margin-bottom: 0;
}
.info-section .item:nth-child(even){
    flex-flow: row-reverse;
}
.info-section .item > div{
    width: 50%;
}

.info-section .item .text-wrapper .h2{
    margin-bottom: 20px;
}
.info-section .item .text-wrapper .h2,.info-section .item .text-wrapper .desc-text{
    text-align: left;
}
.info-section .item .text-wrapper .desc-text{
    line-height: 1.5;
}

.info-section .item:nth-child(even) .text-wrapper{
    padding-left: 70px;
    padding-right: 40px; 
}
.info-section .item:nth-child(even) .text-wrapper h2{
    display: flex;
}
.info-section .item:nth-child(even) .text-wrapper h2:after{
    content: "";
    display: inline-block;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 400px;
    margin-left: 35px;
    background-image: url("../../assets/whyopm/Why OPM-experience topic bar_horizontal.png");
}
.info-section .item:first-child .text-wrapper{
    padding-right: 70px;
    padding-left: 40px; 
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url("../../assets/whyopm/Why OPM-whyopm topic bar_straight.png");
}
@media screen and (max-width: 1400px) {
    .info-section{
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .info-section .item {
        margin-bottom: 40px;
    }
    .info-section .item .text-wrapper{
        margin-bottom: 25px;
        width: 60%;

    }
    .info-section .item .img-wrapper{
        width: 40%;
    }
}
@media screen and (max-width: 1024px) {
    .info-section{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .info-section .item {
        flex-direction: column !important;
        margin-bottom: 40px;
    }

    .info-section .item .text-wrapper,.item .img-wrapper{
        width: 100%;
        padding: 0 25px !important;
    }
    .info-section .item .img-wrapper{
        width: 100%;
    }
}