.wrapper {
    /* important styles */
    display: flex;
    position: relative;
    width: 100%;
  
    /* extra styles */
    
    
    /* margin: 0 auto; */
    /* extra styles */
    
    
  }

  .productsidebarTitle{
    
    
    font-family: Helvetica;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #c3c3c3;
  }

  .productliPadding{
    margin-top: 20px;
  }

  .productsidebarB{
    
      width: 138px;
      height: 58px;
      font-family: AB;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #c2c2c2;
    
  }


  
  .productSidebar {
    /* important styles */
    flex-shrink: 0;
    width: 300px; /* make this whatever you need */
    height: 100vh;
    overflow: hidden;
    position: sticky;
    top: 0;
  
    /* 
      you could use `position: absolute` above, but using
      `sticky` means we don't need to separately move the
      content section over to prevent the elements from
      overlapping
    */
  
    /* extra styles */
    padding: 30px;
    
  }

  .product_content_main{
    padding-right: 165px;
  }
  
  .product_service_content_main {
    /* important styles */
    flex-grow: 1;
    width: calc(100% - 300px);
    color: black;
    padding-left: 67px;
    padding-right: 67px;
    
    /* extra styles */
    
    
   
  }

  /* .opm_service_content p {
    color: black;
  } */
  
  /* make the nav take up more room :P */
  .nav li {
    /* line-height: 1.5; */
  }
  