.award-list-section {
    padding-top: 60px;
    padding-bottom: 105px;
    background-image: url("../../assets/whyopm/Awards-bg2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.award-list-section .item-wrapper {
    margin: auto;
    max-width: 1220px;
    padding: 0 16px;
}

.award-list-section .item-wrapper .item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f9e9bd;
    padding-bottom: 50px;
}

.award-list-section .item-wrapper .item:last-child {
    padding-bottom: 0;
}

.award-list-section .item-wrapper .item p {
    padding: 0 70px;
    width: calc(100% - 540px);
}

.award-list-section .item-wrapper .item div {
    width: 270px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.award-list-section .item-wrapper .item div img {
    max-width: 100%;
    max-height: 180px;
}

@media screen and (max-width: 1400px) {
    .award-list-section .item-wrapper .item p {
        font-size: 28px;
    }

}

@media screen and (max-width: 1024px) {
    .award-list-section .item-wrapper .item p {
        padding: 0 20px;
        font-size: 24px;
        width: calc(100% - 240px);
    }

    .award-list-section .item-wrapper .item div img {
        max-height: 120px;
    }
}

@media screen and (max-width: 640px) {
    .award-list-section {
        padding-top: 35px;
        padding-bottom: 50px;
    }

    .award-list-section .item-wrapper .item p {
        width: calc(100% - 140px);
        font-size: 18px;

    }
}

@media screen and (max-width: 767px) {
    .award-list-section .item-wrapper .item {
        gap: 16px;
    }
}