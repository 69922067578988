.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled {
  background-color: var(--clr-primary-400);
  box-shadow: 0 0 1px 3px var(--clr-primary-400);
}

.opm_solution {
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  background-image: url("../../assets/solution_bg.png");
  background-repeat: no-repeat;
  background-position: center;
}

.opm_solution_frame {
  height: auto;
  /* padding-top: 50px; */
  /* padding-bottom: 50px; */
  display: flex;
  flex-direction: column;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 16px;
}

.opm_solution_frame h2 {
  color: white;
  font-family: Antonio;
  font-size: 51px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.opm_mother_frame {
  display: flex;
}

.opm_solution_icon {
  display: flex;
  padding-top: 50px;
  padding-bottom: 70px;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;



}


.opm_solution_item {
  display: flex;
  width: 180px;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .opm_solution_item {
    width: 100%;
    padding: 0;
  }
}

.opm_solution_item h3 {
  color: white;
  padding-top: 20px;
  font-family: Antonio;
  font-size: 20px;
  text-align: center;

}

.icon_center {
  margin-left: auto;
  margin-right: auto;
}