.method-section{
    min-height: 500px;
    position: relative;
    padding: 0 25px;
    padding-top: 180px;
    padding-bottom: 105px;
    background-color: #fff;

    /* max-width: 1700px;
    margin: auto; */
}
.method-section .item-wrapper{
    display: flex;
    justify-content: space-between;
    max-width: 1700px;
    margin: auto;

}
.method-section .item h2{
    margin-bottom: 15px;
}

.method-section .item{
    z-index: 10;
    background-image: url("../../assets/whyopm/Method-bg.png");
    background-position: center;
    background-size: cover;
    padding: 20px 60px;
    padding-bottom: 60px;
    color: #fff;
    text-align: center;
    max-width: 830px;
    width: 50%;
}
.method-section .item p{
    line-height: 1.59;
}
.method-section .item:first-child{
    background-image: url("../../assets/whyopm/Approach-bg.png");
}
.method-section .item:first-child{
    margin-right: 30px;
}
.method-section .item:nth-child(2){
    margin-left: 30px;
}
.method-section:after{
    content: "";
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 440px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-image: url("../../assets/whyopm/approach_Method-full bg.jpg");
}

.top:before{
    content: "";
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 50px;
    
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    
}

@media screen and (max-width: 1024px) {
    .method-section{
        padding-top: 90px;
        padding-bottom: 50px;
    }
    .method-section .item-wrapper{
        flex-direction: column;
    }
    .method-section .item{
        width: 100%;
        margin: 0px !important;
        margin-bottom: 25px !important;
    }
}