* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: Helvetica;
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}


.navbar_toppest_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 0%;
  padding-top: 20px;

}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

.service_title {

  height: 101px;

  object-fit: contain;
  font-family: Antonio;
  font-size: 70px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.64;
  letter-spacing: normal;

  color: #4f7ac7;
}

.steps-container {
  display: flex;
  flex-direction: column;
}

.steps-item {
  padding: 5px;
  min-height: 80px;
}

.vertical-slider {
  /* height: 380px; */
  margin-top: 25px;
  margin-bottom: 25px;
  width: 25%;
  /* border: 1px solid grey; */
}

.slideMenuTitle {
  font-size: 40px;
  font-family: AB;
  color: #c2c2c2;
  text-align: center;
}

.slideMenuItem {
  font-size: 23px;
  font-family: HR;
  color: #c2c2c2;
  text-align: center;
}

.subTitle {
  font-size: 50px;
  font-family: AB;
}

.business_partner_title {
  font-size: 90px;
  font-family: AR;
  text-align: center;
  color: #343434;

}

.business_partner_content {
  font-size: 22px;
  font-family: HR;
  text-align: center;

}

.subTitle_center {
  font-size: 50px;
  font-family: AB;
  align-self: center;
  font-stretch: normal;
  color: #343434;
}


.subContent_hr_center {
  font-size: 22px;
  font-family: HR;
  color: #343434;
  align-self: center;
  font-stretch: normal;
}

.subContent_ar {
  font-size: 22px;
  font-family: AR;
  color: #343434;
}

.subContent_hr {
  font-size: 22px;
  font-family: HR;
  color: #343434;
}

.subContent_hr_jusify {
  text-align: justify;
}

.paddingLeft {
  padding-left: 15px;
}


.paddingRight {
  padding-right: 15px;
}

.globalContent {
  width: 100%;
  height: auto;
}

.contactusBg {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./assets/contactus_main_bg.png');
}

.service_page_blue_header {
  font-size: 70px;
  font-family: AL;
  color: #4f7ac7;
}

.service_page_blue_header_sub_title {
  font-size: 20px;
  font-family: AL;
  color: #343434;
}

.service_page_blue_header_bottom_line {
  display: flex;
  align-items: center;

  background-repeat: no-repeat;
  background-position: center;
}

.benefit_parts_t4 {
  height: 129px;
  width: 279px;
  padding-left: 20px;
  padding-top: 30px;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url('./assets/t4.png');
  font-size: 10px;
  text-align: center;


}

.benefit_parts_t5 {
  /* height: 129px;
  width: 279px;
  padding-left: 20px;
  padding-top: 30px;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url('./assets/t5.png');
  font-size: 10px;
  text-align: center; */

  padding-left: 40px;
  padding-top: 30px;
  padding-right: 20px;
  background-size: contain;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url('./assets/t5.png');
  font-size: 10px;
  text-align: center;
}

.benefit_parts_t6 {

  height: 129px;
  width: 279px;
  padding-left: 20px;
  padding-top: 30px;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url('./assets/t6.png');
  font-size: 10px;
  text-align: center;
}

.benefit_parts_t7 {

  height: 129px;
  width: 279px;
  padding-left: 20px;
  padding-top: 30px;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url('./assets/t7.png');
  font-size: 10px;
  text-align: center;
}

.benefit_parts_no_num_big {

  height: 259px;
  width: 684px;
  padding-left: 20px;
  padding-top: 30px;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url('./assets/endBiggerbox.png');
  font-size: 10px;
  text-align: center;
}


.benefit_parts_no_num {

  height: 259px;
  width: 321px;
  padding-left: 20px;
  padding-top: 30px;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url('./assets/endSmallbox.png');
  font-size: 10px;
  text-align: center;
}


.benefit_parts_p {
  padding-top: 25px;
  font-size: 10px;
  text-align: center;
}

.bottomBanner {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  align-self: center;
  justify-content: center;
  background: url('./assets//bottombg.png');
}

.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  padding-bottom: 80px;
  padding-top: 80px;
  box-sizing: border-box;

}

.flexDropDownTitle {
  background-color: rgba(234, 236, 239);
  justify-content: space-between;
  padding-left: 200px;
  padding-right: 200px;
}

.dropdownTitle {
  font-size: 40px;
  color: #e1001e;
  font-family: AB;
}

.flex-item-left {

  padding-top: 100px;
  padding-right: 100px;
  padding-left: 100px;
  flex: 50%;
}

.flex-item-right {

  flex-direction: column;
  flex: 50%;
}

.contentUs_title {

  font-size: 91px;
  color: white;
  font-family: AR;
}

.sitemap_title {
  font-size: 91px;
  color: black;
  font-family: AR;
}

.sitemap_subtitle {
  font-size: 35px;
  color: #2baee5;
  font-family: HR;
  line-height: 1.2;
  margin-bottom: 15px;
}

.sitemap_bg {

  color: white;
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 99px;
  padding-bottom: 99px;
}

.sitemap_sub_bg {
  color: white;
  padding-top: 24px;
  padding-bottom: 24px;
}

.sitemap_content_frame {

  color: white;
  display: flex;
  flex-direction: row;


}

.sitemap_sub_content_frame {
  width: 33%;
  display: flex;
  flex-direction: column;
}

.sitemap_sub_content_frame p {
  font-size: 22px;
  color: black;
  font-family: HR;
  line-height: 1.73;
}

.sitemap_sub_content_frame b {
  font-size: 22px;
  color: black;
  font-weight: bold;
  font-family: HR;
  line-height: 1.73;
}


.contactUsFooter_blue {
  display: flex;
  align-items: center;
  background-color: #002361;
  padding: 20px;
}

.contactUsFooter_grey {
  display: flex;
  align-items: center;
  background-color: #7c98b4;
  padding: 20px;
}

.contactUsFooterContent {
  display: flex;
  flex: 1;
  justify-content: center;

}

.contactUsIn {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-self: center;



}

.contactUsFooterinsideContent {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  justify-content: center;

}

.footerTitle {
  font-size: 40px;
  font-family: AB;
  color: white;
}

.footerContent {
  font-size: 1 2px;
  font-family: HR;
  color: white;
}

.w3c_logo {
  width: 98px;
  height: 34px;
}

.footerLogo {
  width: 20px;
  height: 20px;
}

@media (max-width: 1000px) {
  .flex-container {
    flex-direction: column;
    text-align: center;
  }

  .miniBar {
    display: block;
  }

  .navigation-menu ul {
    display: none;
  }

  #normalNavBar {
    display: none;
  }

  .sitemap_content_frame {
    display: flex;
    flex-direction: column;

  }

  #partner_desc {
    display: none;
  }

  .navbarTItle {
    color: #343434;
    font-weight: 300;
    margin-top: 37px;
    line-height: 1.26;
    font-family: Ar;
  }

  .navbar .contactUsIn {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-self: center;
  }

  .descArea {
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 100px;
  }


  #opmLogo {
    display: none;
  }

  .contactUsFooterinsideContent {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: center;

  }

  .sitemap_sub_content_frame {
    /* padding-top: 50px; */
    width: 33%;
    display: flex;
    flex-direction: column;
  }

  .sitemap_subtitle {
    font-size: 35px;
    color: #2baee5;
    font-family: HR;
    line-height: 1.2;

  }

  /* .featureOuter {
    display: none;
  } */
}




@font-face {
  font-family: HR;
  src: url(./assets/fonts/Helvetica.ttc);
}

@font-face {
  font-family: AR;
  src: url(./assets/fonts/Antonio-Regular.ttf);
}

@font-face {
  font-family: AB;
  src: url(./assets/fonts/Antonio-Bold.ttf);
}

@font-face {
  font-family: AL;
  src: url(./assets/fonts/Antonio-Light.ttf);
}

@font-face {
  font-family: HB;
  src: url(./assets/fonts/Helvetica-Bold.ttf);
}