.opm_footer {
    display: flex;
    align-items: center;

    background-color: rgba(9, 24, 78);
    background-repeat: no-repeat;
    background-position: center;
    
}

.opm_footer img{
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
    
}