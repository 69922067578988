.miniBarToggle {
    width: 30px;
    height: 30px;
    margin-right: 30px;
    margin-left: 30px;
}

.miniBarLogo {
    width: 250px;
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
}

.miniBar {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: row;
    display: none;

}

.navigation-menu li:hover {
    background-color: #eee;
}

.navigation-menu.expanded ul {
    display: block;
    overflow: auto;
}

.navigation-menu {
    margin-left: 0px;
}

.navigation-menu ul {
    display: flex;
    padding: 0;
    flex-direction: column;
}

.navigation-menu li {

    list-style-type: none;
    margin: 0 1rem;
}

.navigation-menu li a {

    text-decoration: none;
    display: block;
    width: 100%;
}

.minibg {
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-image: url("../../assets/navBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

}

.title1 {
    font-size: 40px;
    color: black;
    line-height: 1.29;
    font-family: AL;
    align-self: flex-start;
}

.title1Bold {
    font-family: AB;
}

.topnav {
    overflow: hidden;
    background-color: white;
}

.topnav a {

    display: block;
    color: black;
    text-align: left;
    padding: 14px;
    text-decoration: none;
    font-size: 22px;
    font-family: AL;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}

.topnav a.active {
    background-color: #04AA6D;
    color: white;
}






.solution_navbar {
    justify-content: space-between;
    align-items: center;
    background-image: url("../../assets/solutionnav_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.solution_navbar-links {
    display: flex;
    justify-content: flex-end;
    margin-right: 196px;
    align-items: center;
}

.right {
    float: right;
}

.service_toppest_string {
    font-size: 21px;
    color: #FFFFFF;
    margin-right: 30px;
}

.service_toppest_string_right {
    font-size: 21px;
    position: absolute;
    color: #FFFFFF;
    float: right;
    justify-content: right;
    text-align: right;
    margin-right: 30px;
    right: 16px;
    padding-right: 41px;
    align-self: flex-end;

}

.opm_toppest_item {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 0%;
    padding-top: 20px;

}

.solution_navbar_links_logo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


/* .solution_logo_css {
    margin-top: 68px;

    max-width: 438px;

}

.solution_h1_st {
    margin-top: 88px;
    margin-bottom: 130px;

    max-width: 30px;

} */

.gpt3__navbar-links_container {
    display: inline-block;
    display: flex;
    width: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;




}

.product_outer {
    display: flex;
    width: 100%;

    background-image: url("../../assets/product_link_bg.png");
    justify-content: center;
}


.service_h1_st {
    color: #FFFFFF;
    font-weight: 300;
    margin-top: 37px;
    line-height: 1.26;
    justify-content: end;
    align-items: flex-end;
    font-family: Antonio-Light-;
}

.service_h1_rd {
    color: #FFFFFF;
}

.product_navbar_links_container {
    display: inline-block;
    display: flex;
    width: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;

}


.product_navbar_links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #FFFFFF;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}