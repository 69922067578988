.opm_partner {
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    background-image: url("../../assets/partner_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    
    
}

.padd{
  padding-left: 30px;
  padding-right: 30px;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 16px;
  }

.opm_partner_h1_title {
    position:relative;
    margin:0 auto;
    clear:left;
    font-size: 91px;
    height:auto;
    padding-top: 60px;
    line-height: 0.46;
    font-family: Antonio;
    text-align: center;
    
    
}



.opm_partner_content{
    position:relative;
    margin:0 auto;
    clear:left;
    font-size: 22px;
    height:auto;
    object-fit: contain;
  
    padding-top: 34px;
    line-height: 35px;
    padding-left: 262px;
    padding-right: 262px;
    text-align: center;
}

#mainContainer {
    position: relative;
    height: 100%;
    width: 100%;
  }

#topContainer {
    border: 1px solid red;
    position: absolute;
    width: 100%;
  }
  #bottomContainer {
    border: 1px solid blue;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100px;
    width: 100%;
  }
  