.wrapper {
  /* important styles */
  display: flex;
  position: relative;
  width: 100%;

  /* extra styles */


  /* margin: 0 auto; */
  /* extra styles */


}

.flex_column {
  display: flex;
  flex-direction: column;
  padding-top: 0%;
  padding-bottom: 0%;

}

.flex_row {

  display: flex;
  flex-direction: row;
  padding-top: 0%;
  padding-bottom: 0%;


}

.jobContentTitle {
  font-weight: bold;
  font-size: 22px;
  font-family: HB;
  line-height: 1.2;
  margin-top: 30px;
  margin-bottom: 30px;
}

.jobContentList {

  font-size: 22px;
  font-family: HR;
  line-height: 1.2;
}

.readbtn {
  /* margin-top: 30px; */
  margin-bottom: 20px;
  /* font-size: 22px; */
  font-family: HR;
  line-height: 1.2;
  cursor: pointer;
  display: inline-block;
}

.sline {
  height: 3.5px;
  width: 100%;
  background-color: #c3c3c3;
  position: relative;

}

.sline:after {
  content: "";
  display: block;
  position: absolute;
  right: 50px;
  width: 10px;
  height: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  background-color: #c3c3c3;
  z-index: 15;

}

.sline:before {
  content: "";
  display: block;
  position: absolute;
  right: 40px;
  width: 30px;
  height: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  z-index: 10;
}

.job_img {
  width: 22px;
  height: 22px;
  object-fit: contain;
  margin-right: 8px;
}


.applyBtn {
  background-color: #e1001e;
  padding: 8px 20px;
  border: 0;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 16px;
}

.job_title {
  color: #002361;
  font-family: AB;
}

/* 
  input[type=text], select, textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 35px;
    resize: vertical;
  } */

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type=submit] {
  background-image: url(../../assets/contactusButton.png);
  color: white;

  background-size: auto;
  border: none;

  cursor: pointer;

}

input[type=submit]:hover {
  background-color: #45a049;
}


.container {
  border-radius: 5px;
  font-family: HR;


}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 100%;
  font-family: HR;
  border-radius: 30px;
  font-size: 35px;
  margin-top: 6px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 800px) {

  .col-75,
  input[type=submit] {
    width: 100%;
    margin-top: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.letter_arrow {
  margin-top: 20px;
  width: 40px;
  height: 30px;
  margin-right: 80px;
  margin-left: 60px;
}

.submitBtn {
  background-image: url(../../assets/contactusButton.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

.sendBtn {
  font-size: 30px;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  font-family: AR;
}

.job-item {
  max-width: 1050px;
  margin: auto;
  margin-bottom: 20px;
}

.job-item .joinUs-info-wrapper {
  margin-bottom: 50px;
}

.job-item .joinUs-info-wrapper div:first-child {
  margin-right: 80px;
}

.job-item ul {
  padding-left: 25px;
  line-height: 1.45;
  margin-bottom: 40px;
}

.joinUs-heading {
  margin-bottom: 30px;
}

.k-listgroup {
  background-color: transparent;
  border: 0 !important;
  padding-top: 65px;
}

.joinUsbg {
  background-image: url(../../assets/joinus_bg.png);
  background-size: cover;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 250px;
}

ul li:last-child .sline {
  display: none;
}

@media screen and (max-width: 1400px) {}

@media screen and (max-width: 1024px) {
  .job-item {
    margin-bottom: 10px;
  }

  .job-item .joinUs-info-wrapper {
    margin-bottom: 25px;
  }

  .joinUsbg {
    padding-bottom: 100px;
  }

  .job-item .flex-container {
    text-align: left;
  }

  .k-listgroup {
    padding-top: 35px;
  }

  .job-item .joinUs-info-wrapper div:first-child {
    margin-right: 40px;
  }

  .job-item .job_img {
    width: 18px;
    height: 18px;
  }

  .applyBtn {
    padding: 8px 10px;
  }

  .joinUs-heading {
    margin-bottom: 15px;
  }
}