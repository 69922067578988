.wrapper {
    /* important styles */
    display: flex;
    position: relative;
    width: 100%;
  
    /* extra styles */
    
    
    /* margin: 0 auto; */
    /* extra styles */
    
    
  }
/* 
  .bottomHeight{
    padding-bottom: 100px;
  } */

  .joinUsbg{
    background-image: url(../../assets/joinus_bg.png);
    background-size: cover;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .joinUs-filter-wrapper{
    padding: 0 25px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #eaecef;
  }
  .joinUs-filter-list{
    display: flex;
    max-width: 1130px;
    margin: auto;
    justify-content: space-around;
  }
  @media screen and (max-width: 1024px) {
    .joinUs-filter-wrapper{
      padding-top: 25px;
      padding-bottom: 30px;
    }
  }

  @media screen and (max-width: 640px) {
    .joinUs-filter-list{
      flex-direction: column;

    }

  }
  /* input[type=text], select, textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 35px;
    resize: vertical;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  
  input[type=submit] {
    background-image: url(../../assets/contactusButton.png);
    color: white;
    
    background-size: auto;
    border: none;
    
    cursor: pointer;
  
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  
  .container {
    border-radius: 5px;
    font-family: HR;
    

  }
  
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  
  .col-75 {
    float: left;
    width:100%;
    font-family: HR;
    border-radius:30px;
    font-size: 35px;
    margin-top: 6px;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and (max-width: 800px) {
     .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .letter_arrow{
    margin-top: 20px;
    width: 40px;
    height: 30px;
    margin-right: 80px;
    margin-left: 60px;
  }

.submitBtn{
  background-image: url(../../assets/contactusButton.png);
  width: 100%;
  
  background-repeat: no-repeat;
  background-size: 100% 100%;
  
}

.letter_arrow_submit{
  width: 40px;
  height: 30px;
  margin-right: 25px;
  justify-content: center;
  align-self: center;
}

.sendBtn{
  font-size: 30px;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  font-family: AR;
} */