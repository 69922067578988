.application_wapper{
    display: flex;
    width: 100%;
    

}

.application_wapper .title-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  

}



.service-wapper{

  margin-right: 80px;
  
}
.column{
  flex-direction: column;
}


.applicationImg{
 width: 775px;
 height: 535px;
}

.applicationdevImg{
  width: 775px;
 height: 535px;
}

.application-lineheight{
  line-height: 1.59;
}

.abStyle{
  font-family: AB;
}

.hrStyle{
  font-family: HR;
}












.appDevTitleVertical{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 75px;
    /* padding-left: 80px; */
}

.appDevOuter{
     width: 100%;
     padding: 0px;
}

.appDevTitleContetnHon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.appDevTitleContetnHon h3{
    
    font-size: 70px;
    
    font-family: Antonio;
    
    
    font-weight: 300;

    font-stretch: normal;
    font-style: normal;
    line-height: 0.64;
    letter-spacing: normal;
    color: #4f7ac7;
}

.appDevTitleContetnHon img{
    width: 68px;
    display: block;
    margin-left: auto;
    align-self: center;
    right: 0px;
    height: 90px    ;
}

.applicationline{
    display: flex;
    align-items: center;
    
    background-repeat: no-repeat;
    background-position: center;
}

.applicationline img{
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}



.applicationImg img{
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.quote{
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
}

.quote label{
    align-self: center ;
    color: #c2c2c2;
    font-size: 22px;
    font-family: Helvetica;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: center;
    margin-top: 10px;
}

.quote img{
    display: flex;
    align-self: flex-start;
}

.quote{
    display: flex;
}

.vertical{
    display: flex;
    flex-direction: column;
    width: 50%;

    padding-left: 20px;
  }

  .vertical h3{
    font-size: 50px;
  }

  
.vertical_content{
    
    height: 84.2px;
    
    object-fit: contain;
    font-family: Helvetica;
    font-size: 22px;
    font-weight: normal;
    
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
}

.subContent_hr{
    align-self: center;
    /* padding-left: 16px; */
  }
  
.vertical_content2{
    
    height: 84.2px;
    margin-bottom: 18px;
    object-fit: contain;
    font-family: Helvetica;
    font-size: 22px;
    font-weight: normal;
    margin-left: 16.3px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
  }

.vertical_content_logo{
    width: 93px;
    height: 93px;
    margin-right: 16px;
    object-fit: contain;
}

.vertical_title{
    font-size: 50px;

    
    font-family: Antonio;
    font-size: 50px;
    font-weight: bold;
    
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    
    color: #343434;
}


  
  
  .horizontal{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 78px;
  }

  

  .vertical_content2{
    font-size: 17px;
    align-self: center;    
    padding-left: 15px;
  }